import _es6Object from "../../modules/es6.object.get-own-property-descriptor";
import _core from "../../modules/_core";
var exports = {};
_es6Object;
var $Object = _core.Object;

exports = function getOwnPropertyDescriptor(it, key) {
  return $Object.getOwnPropertyDescriptor(it, key);
};

export default exports;